#account-dialog-button {
    float: right;
}

#account-dialog {
    position: absolute;
    background-color: $white;

    top: $ui-top-height;
    right: 0;
    width: 300px;
    height: 500px;
    z-index: 1001;
    border-radius: 0px 0px 0px 20px;
    padding: 5px 0px;


    display: flex;
    flex-direction: column;


    .account-dialog-header {
        flex: 0;
        flex-basis: 50px;
        border-bottom: 1px solid $gray20;

        h3 {
            margin-top: 10px;
            margin-left: 10px;
        }
    }

    .account-dialog-content {
        flex: 1;
    }

    .account-dialog-bottom {
        flex: 0;
        flex-basis: 40px;

        display: flex;
        flex-direction: row;
        padding: 0px 10px;

        button {
            float: right;
        }

    }
}