.dialog-outer {

    display: flex;
    flex-direction: column;

    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;

    border-radius: 10px 10px 10px 10px;

    .dialog-header {
        flex: 0;

        border-radius: 10px 10px 0px 0px;
        background-color: $topmenu-background;
        color: white;

        .dialog-header-title {
            color: white;
            padding: 10px 20px;
            font-size: 1.3rem;
        }

    }

    .dialog-body {
        flex: 1;
        padding: 20px 20px;
        padding-top: 0px;
        margin-top: 0px;
    }

    .dialog-footer {
        flex: 0;

        background-color: $topmenu-background;
        color: white;
        border-radius: 0px 0px 10px 10px;
        text-align: right;
        padding: 10px;

        button {
            margin-left: 10px;
            min-width: 100px;
        }
    }



}