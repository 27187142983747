.left-menu-panel {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    max-width: 400px;
    min-width: 200px;
    margin: auto;
    height: 100dvh;
    //margin-top: 200px;
    animation: slidein 0.5s;

    // box-shadow: 4px 0px 2px 0px #0005;

    ul {
        display: block;
        list-style-type: none;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 00px;

        li {
            cursor: pointer;
            height: 50px;
            border-bottom: 1px solid $blue20;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;

            font-weight: bold;

            &:hover {
                background-color: #eee;
            }

        }
    }
}

@keyframes slidein {
    from {
        left: -300px;
    }

    to {
        left: 0;
    }
}

$bottom-menu-panel-width: 600px;



@media screen and (min-width: $mobile-breakpoint) {
    .bottom-menu-panel {
        width: $bottom-menu-panel-width;
        left: calc(50vw - #{$bottom-menu-panel-width} / 2);
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .bottom-menu-panel {
        width: 100vw;
        left: 0;
    }
}

.bottom-menu-panel {
    display: flex;
    background-color: $white;
    flex-direction: column;
    position: absolute;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    //top: calc(100dvh - 300px);
    //margin-top: 200px;
    //animation: slideup300 0.5s;
    //height: 800px;

    ul {
        display: block;
        list-style-type: none;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        user-select: none;

        li {
            cursor: pointer;
            height: 50px;
            border-bottom: 1px solid $blue20;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;

            font-weight: bold;

            &:hover {
                background-color: #eee;
            }

        }
    }
}

// bottom-menu-panel-items-150px
//
$sizes: 50px, 100px, 150px, 200px, 250px, 300px, 350px, 400px, 450px;

@each $size in $sizes {
    .bottom-menu-panel-items-#{$size} {
        top: calc(100dvh - #{$size});
        animation: slideup-#{$size} 0.3s;
        height: $size;
    }

    @keyframes slideup-#{$size} {
        from {
            top: 100dvh;
        }

        to {
            top: calc(100dvh - #{$size});
        }
    }
}