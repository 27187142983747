html,
body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&family=Roboto&family=Roboto+Condensed&display=swap");
  font-family: "Roboto", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#ui-login {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #284064;
  height: 100dvh;
  width: 100vw;
  padding-top: 100px;
}
#ui-login .login-box {
  background-color: #fff;
  height: 140px;
  width: 500px;
  max-width: 80vw;
  margin: auto;
  border-radius: 20px;
  padding: 20px;
  padding-bottom: 50px;
}
#ui-login .login-box.disabled {
  filter: grayscale(1);
  pointer-events: none;
  opacity: 0.7;
}
#ui-login .login-box .login-failed {
  color: red;
  text-transform: uppercase;
  text-align: center;
}

#ui-main {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}
#ui-main #ui-main-left {
  flex: 0;
  flex-direction: column;
  flex-basis: 50px;
  background-color: #284064;
}
#ui-main #ui-main-top {
  flex: 0;
  flex-basis: 50px;
  background-color: #284064;
  color: #fff;
  display: flex;
  flex-direction: column;
}
#ui-main #ui-main-top .ui-main-top-upper {
  flex: 1;
  display: flex;
  flex-direction: row;
}
#ui-main #ui-main-top .ui-main-top-upper button {
  height: 100%;
}
#ui-main #ui-main-top .ui-main-top-links {
  flex: 0;
  flex-basis: 0px;
  display: flex;
  flex-direction: row;
}
#ui-main #ui-main-top .ui-main-top-links .link {
  min-width: 200px;
  background-color: #284064;
  color: #fff;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
}
#ui-main #ui-main-top .ui-main-top-links .link:hover {
  background-color: #1a293f;
}
#ui-main #ui-main-content {
  flex: 1;
  background-color: #eee;
  height: calc(100dvh - 50px);
  overflow-y: scroll;
}
@media screen and (max-width: 600px) {
  #ui-main #ui-bottom-menu {
    flex: 0;
    flex-basis: calc(72px + env(safe-area-inset-bottom, 0px));
    background-color: transparent;
  }
  #ui-main #ui-bottom-menu #ui-bottom-menu-ui {
    margin: auto;
    max-width: 600px;
    height: calc(72px + env(safe-area-inset-bottom, 0px));
    background-color: #284064;
  }
}
@media screen and (min-width: 600px) {
  #ui-main #ui-bottom-menu {
    position: absolute;
    width: 600px;
    bottom: 0px;
    left: calc(50dvw - 600px / 2);
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
  }
  #ui-main #ui-bottom-menu #ui-bottom-menu-ui {
    margin: auto;
    height: calc(72px + env(safe-area-inset-bottom, 0px));
    background-color: #284064;
  }
}
#ui-main #ui-bottom-menu-ui {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
#ui-main #ui-bottom-menu-ui .menuitem {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  gap: 10px;
  color: white;
  text-align: center;
  margin-bottom: env(safe-area-inset-bottom, 0px);
}
#ui-main #ui-bottom-menu-ui .menuitem .icon {
  margin-top: 8px;
  text-align: center;
}
#ui-main #ui-bottom-menu-ui .menuitem .label {
  margin-top: 5px;
  font-size: 0.8rem;
}
#ui-main #ui-bottom-menu-ui .menuitem.active {
  background-color: #375788;
}
#ui-main #ui-main-bottom {
  display: flex;
  flex-direction: row;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.flex0 {
  flex: 0;
}

.flex1 {
  flex: 1;
}

.flexb100 {
  flex: 0;
  flex-basis: 100px;
}

.flexb10 {
  flex: 0;
  flex-basis: 10px;
}

.flexb20 {
  flex: 0;
  flex-basis: 20px;
}

.flexb40 {
  flex: 0;
  flex-basis: 40px;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w50 {
  width: 50%;
}

.p10 {
  padding: 10px;
}

.ph10 {
  padding: 0px 10px;
}

.m10 {
  margin: 10px;
}

.pb50 {
  padding-bottom: 50px;
}

.page-header {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #284064;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.page-header .header {
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.page-header .menu {
  flex: 0;
  flex-basis: 40px;
  padding-top: 5px;
  padding-right: 10px;
  text-align: right;
  cursor: pointer;
}

table {
  border-collapse: collapse;
}
table.tbl {
  width: 100%;
  border-top: 1px solid #ccc;
  font-size: 16px;
}
table.tbl th {
  white-space: nowrap;
  text-align: left;
  text-transform: uppercase;
}
table.tbl tr {
  border-bottom: 1px solid #ccc;
}
table.tbl tr:nth-child(odd) {
  background-color: #fff;
}
table.tbl tr td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
}
table.tbl tr.large {
  font-size: 18px;
}
table.tbl tr.clickable {
  cursor: pointer;
}
table.tbl tr.clickable:hover {
  background-color: #cbdcf6;
}
table.tbl-small {
  font-size: 0.8rem;
}

#overlay,
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100dvh;
  animation: fadein 0.3s;
}
#overlay.dark,
.overlay.dark {
  background-color: rgba(0, 0, 0, 0.4);
}
#overlay.darker,
.overlay.darker {
  background-color: rgba(0, 0, 0, 0.6);
}
#overlay.hidden,
.overlay.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}
#overlay .spinner,
.overlay .spinner {
  color: rgba(255, 255, 255, 0.8);
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
#overlay .spinner .progress-bar,
.overlay .spinner .progress-bar {
  margin-top: 20px;
  width: 50%;
  margin: auto;
  border: 1px solid white;
}
#overlay .spinner .progress-bar .progress,
.overlay .spinner .progress-bar .progress {
  width: 50%;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.4666666667);
}

.frm-section {
  background-color: white;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.frm-editable {
  display: flex;
  flex-direction: row;
}
.frm-editable .content {
  flex: 1;
  padding-right: 20px;
}
.frm-editable .edit-ico {
  cursor: pointer;
  flex: 0;
  flex-basis: 50px;
  display: flex;
  align-items: center;
  text-align: center;
}

.frm {
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid #6589c0;
}
.frm label {
  display: block;
  font-weight: 500;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.frm input,
.frm textarea,
.frm select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: calc(100% - 20px);
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
.frm input::-moz-placeholder, .frm textarea::-moz-placeholder, .frm select::-moz-placeholder {
  font-style: italic;
}
.frm input::placeholder,
.frm textarea::placeholder,
.frm select::placeholder {
  font-style: italic;
}
.frm select {
  width: 100%;
}
.frm textarea {
  min-height: 100px;
}
.frm .data {
  font-size: 1.2rem;
  min-height: 1.3rem;
  white-space: pre-wrap;
}

.frm-image {
  margin: -10px -10px 0px -10px;
}
.frm-image .image {
  width: 100%;
  aspect-ratio: 1.7;
  -o-object-fit: cover;
     object-fit: cover;
}

.btn-container {
  padding: 0px 10px;
}

.btn-primary {
  display: block;
  padding: 5px 10px;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  white-space: nowrap;
  padding: 5px 15px;
  max-width: calc(0.8 * 600px);
  font-size: 16px;
  margin: auto;
  border: 1px solid #466fac;
  background-color: #466fac;
  color: white;
}
.btn-primary:disabled {
  opacity: 0.5;
}

.btn-secondary {
  display: block;
  padding: 5px 10px;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  white-space: nowrap;
  padding: 5px 15px;
  max-width: calc(0.8 * 600px);
  font-size: 16px;
  margin: auto;
  border: 1px solid #cccccc;
  background-color: #333333;
  color: #e6e6e6;
}
.btn-secondary:disabled {
  opacity: 0.5;
}

.left-menu-panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  max-width: 400px;
  min-width: 200px;
  margin: auto;
  height: 100dvh;
  animation: slidein 0.5s;
}
.left-menu-panel ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.left-menu-panel ul li {
  cursor: pointer;
  height: 50px;
  border-bottom: 1px solid #466fac;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  font-weight: bold;
}
.left-menu-panel ul li:hover {
  background-color: #eee;
}

@keyframes slidein {
  from {
    left: -300px;
  }
  to {
    left: 0;
  }
}
@media screen and (min-width: 600px) {
  .bottom-menu-panel {
    width: 600px;
    left: calc(50vw - 600px / 2);
  }
}
@media screen and (max-width: 600px) {
  .bottom-menu-panel {
    width: 100vw;
    left: 0;
  }
}
.bottom-menu-panel {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  position: absolute;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.bottom-menu-panel ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.bottom-menu-panel ul li {
  cursor: pointer;
  height: 50px;
  border-bottom: 1px solid #466fac;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  font-weight: bold;
}
.bottom-menu-panel ul li:hover {
  background-color: #eee;
}

.bottom-menu-panel-items-50px {
  top: calc(100dvh - 50px);
  animation: slideup-50px 0.3s;
  height: 50px;
}

@keyframes slideup-50px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 50px);
  }
}
.bottom-menu-panel-items-100px {
  top: calc(100dvh - 100px);
  animation: slideup-100px 0.3s;
  height: 100px;
}

@keyframes slideup-100px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 100px);
  }
}
.bottom-menu-panel-items-150px {
  top: calc(100dvh - 150px);
  animation: slideup-150px 0.3s;
  height: 150px;
}

@keyframes slideup-150px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 150px);
  }
}
.bottom-menu-panel-items-200px {
  top: calc(100dvh - 200px);
  animation: slideup-200px 0.3s;
  height: 200px;
}

@keyframes slideup-200px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 200px);
  }
}
.bottom-menu-panel-items-250px {
  top: calc(100dvh - 250px);
  animation: slideup-250px 0.3s;
  height: 250px;
}

@keyframes slideup-250px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 250px);
  }
}
.bottom-menu-panel-items-300px {
  top: calc(100dvh - 300px);
  animation: slideup-300px 0.3s;
  height: 300px;
}

@keyframes slideup-300px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 300px);
  }
}
.bottom-menu-panel-items-350px {
  top: calc(100dvh - 350px);
  animation: slideup-350px 0.3s;
  height: 350px;
}

@keyframes slideup-350px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 350px);
  }
}
.bottom-menu-panel-items-400px {
  top: calc(100dvh - 400px);
  animation: slideup-400px 0.3s;
  height: 400px;
}

@keyframes slideup-400px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 400px);
  }
}
.bottom-menu-panel-items-450px {
  top: calc(100dvh - 450px);
  animation: slideup-450px 0.3s;
  height: 450px;
}

@keyframes slideup-450px {
  from {
    top: 100dvh;
  }
  to {
    top: calc(100dvh - 450px);
  }
}
h1,
h2,
h3,
p,
label {
  color: #284064;
}

#account-dialog-button {
  float: right;
}

#account-dialog {
  position: absolute;
  background-color: #fff;
  top: 50px;
  right: 0;
  width: 300px;
  height: 500px;
  z-index: 1001;
  border-radius: 0px 0px 0px 20px;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
}
#account-dialog .account-dialog-header {
  flex: 0;
  flex-basis: 50px;
  border-bottom: 1px solid #cccccc;
}
#account-dialog .account-dialog-header h3 {
  margin-top: 10px;
  margin-left: 10px;
}
#account-dialog .account-dialog-content {
  flex: 1;
}
#account-dialog .account-dialog-bottom {
  flex: 0;
  flex-basis: 40px;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
}
#account-dialog .account-dialog-bottom button {
  float: right;
}

.dialog-outer {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
}
.dialog-outer .dialog-header {
  flex: 0;
  border-radius: 10px 10px 0px 0px;
  background-color: #284064;
  color: white;
}
.dialog-outer .dialog-header .dialog-header-title {
  color: white;
  padding: 10px 20px;
  font-size: 1.3rem;
}
.dialog-outer .dialog-body {
  flex: 1;
  padding: 20px 20px;
  padding-top: 0px;
  margin-top: 0px;
}
.dialog-outer .dialog-footer {
  flex: 0;
  background-color: #284064;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: right;
  padding: 10px;
}
.dialog-outer .dialog-footer button {
  margin-left: 10px;
  min-width: 100px;
}

.toolbar-container {
  display: flex;
  flex-direction: row;
  margin: 4px 0px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.toolbar-container .toolbar-item {
  flex: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid #284064;
  margin-right: 10px;
  white-space: nowrap;
  padding: 5px 15px;
  padding-right: 5px;
  border-radius: 8px;
  background-color: #466fac;
  color: white;
}
.toolbar-container .toolbar-item .icon {
  padding-right: 10px;
}
.toolbar-container .toolbar-item .text {
  padding-right: 10px;
}
.toolbar-container .toolbar-item.disabled {
  background-color: #6589c0;
  opacity: 0.8;
  cursor: default;
}

.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: flex-start;
}
.card-container .card {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #eee;
  box-shadow: 5px 4px 7px -4px rgba(0, 0, 0, 0.42);
  height: 100px;
}
@media screen and (max-width: 600px) {
  .card-container .card {
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: calc(2 * 600px)) {
  .card-container .card {
    width: calc(50% - 10px / 2);
  }
}
@media screen and (min-width: calc(2 * 600px)) and (max-width: calc(3 * 600px)) {
  .card-container .card {
    width: calc(33.333333% - 2 * 10px / 3);
  }
}
@media screen and (min-width: calc(3 * 600px)) {
  .card-container .card {
    width: calc(25% - 3 * 10px / 4);
  }
}
.card-container .card-boat {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #eee;
  box-shadow: 5px 4px 7px -4px rgba(0, 0, 0, 0.42);
  border-radius: 0;
}
@media screen and (max-width: 600px) {
  .card-container .card-boat {
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: calc(2 * 600px)) {
  .card-container .card-boat {
    width: calc(50% - 10px / 2);
  }
}
@media screen and (min-width: calc(2 * 600px)) and (max-width: calc(3 * 600px)) {
  .card-container .card-boat {
    width: calc(33.333333% - 2 * 10px / 3);
  }
}
@media screen and (min-width: calc(3 * 600px)) {
  .card-container .card-boat {
    width: calc(25% - 3 * 10px / 4);
  }
}
.card-container .card-boat .boat-image {
  width: 100%;
  aspect-ratio: 1.7;
  -o-object-fit: cover;
     object-fit: cover;
}
.card-container .card-boat .boat-info {
  padding: 10px;
  min-height: 100px;
}
.card-container .card-boat .boat-info .boat-name {
  font-size: 1.2rem;
}
.card-container .card-boat .boat-info .boat-type {
  margin-top: 4px;
  font-size: 1rem;
}
.card-container .card-boat .boat-info .boat-description {
  display: block;
  margin-top: 4px;
  font-size: 0.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* Needed to make it work */
  overflow: hidden;
}
.card-container .card-center {
  margin: auto;
}
.card-container .add-card {
  display: flex;
}
.card-container .data-card {
  display: grid;
  grid-template-columns: calc(100px - 10px) auto;
  padding: 10px;
}
.card-container .data-card .card-info {
  display: inline-block;
  width: 100%;
  min-width: 0;
}
.card-container .data-card .card-image {
  width: calc(100px - 2 * 10px);
  aspect-ratio: 1;
  background-color: #cbdcf6;
  border-radius: 10px;
}
.card-container .data-card .title {
  font-size: 1.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.card-container .data-card .card-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.plus-container {
  flex: 1;
  width: 50px;
  aspect-ratio: 1;
  margin: auto;
  background-color: #cbdcf6;
  color: #375788;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
}
.plus-container .icon {
  margin-top: 7px;
  display: block;
  flex: 1;
}

.settings-container {
  background-color: white;
  box-shadow: 5px 4px 7px -4px rgba(0, 0, 0, 0.42);
  padding: 10px 0px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.settings-container .header {
  padding: 0px 10px;
  font-size: 1.2rem;
  color: #284064;
  border-bottom: 1px solid #6589c0;
}
.settings-container .settings-item {
  display: grid;
  grid-template-columns: auto 40px;
  cursor: pointer;
  padding: 8px;
  padding-left: 16px;
}
.settings-container .settings-item .description {
  font-size: 0.8rem;
  font-style: italic;
}
.settings-container .settings-item .ico {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.settings-item:not(:last-child) {
  border-bottom: 1px solid #cbdcf6;
}

.overflow-scroll {
  overflow-y: scroll;
}

.events .event {
  box-shadow: 5px 4px 7px -4px rgba(0, 0, 0, 0.42);
  background-color: white;
  min-height: 80px;
  display: grid;
  grid-template-columns: 60px auto;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.events .event .ico {
  background-color: #cbdcf6;
  aspect-ratio: 1;
  border-radius: 50%;
}
.events .event .ico img {
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
}
.events .event .texts {
  padding-left: 10px;
}
.events .event .texts .title {
  font-size: 1.2rem;
}
.events .event .texts .timestamp {
  min-width: 0;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
}
.events .event .texts .description {
  min-width: 0;
  font-size: 0.8rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.file-upload,
.image-upload {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.file-upload .container,
.image-upload .container {
  background-color: #ffffff;
  width: 90%;
  max-width: 34.37em;
  position: relative;
  margin: 3.12em auto;
  padding: 3.12em 1.25em;
  border-radius: 0.43em;
  box-shadow: 5px 4px 7px -4px rgba(0, 0, 0, 0.42);
}
.file-upload input[type=file],
.image-upload input[type=file] {
  display: none;
}
.file-upload label,
.image-upload label {
  display: block;
  position: relative;
  background-color: #375788;
  color: #ffffff;
  font-size: 1.12em;
  font-weight: 500;
  text-align: center;
  width: 18.75em;
  padding: 10px 0;
  margin: auto;
  border-radius: 0.31em;
  cursor: pointer;
}
.file-upload .camera-input,
.image-upload .camera-input {
  max-width: 120px;
  aspect-ratio: 1.6;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.file-upload .num-of-files,
.image-upload .num-of-files {
  font-weight: 400;
  text-align: center;
  margin: 1.25em 0 1.87em 0;
}
.file-upload .card-container,
.image-upload .card-container {
  margin-top: 10px;
}
.file-upload .file-card,
.image-upload .file-card {
  font-weight: 500;
  color: #375788;
  padding: 1.1em 1em;
  display: flex;
  justify-content: space-between;
}
.file-upload .image-card,
.image-upload .image-card {
  position: relative;
  font-weight: 500;
  color: #375788;
  margin: 1.1em 1em;
  text-align: center;
}
.file-upload .image-card .image,
.image-upload .image-card .image {
  width: 100%;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.file-upload .image-card .image-preview,
.image-upload .image-card .image-preview {
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.file-upload .image-card .image-preview .image-preview-img,
.image-upload .image-card .image-preview .image-preview-img {
  width: auto;
  max-height: 100dvh;
  max-width: 100dvw;
  border-radius: 0;
  touch-action: pan-right pinch-zoom;
  -o-object-fit: contain;
     object-fit: contain;
}
.file-upload .image-card .remove,
.image-upload .image-card .remove {
  position: absolute;
  width: 40px;
  aspect-ratio: 1;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.file-upload .image-card .filesize,
.image-upload .image-card .filesize {
  display: none;
}

.image-upload-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
.limit-width-to-mobile {
  max-width: 600px;
}

.wide-container {
  padding-left: 10px;
  padding-right: 10px;
}

.flex-container-with-header {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 50px - calc(72px + env(safe-area-inset-bottom, 0px)));
}

#bottomdialog {
  position: absolute;
  background-color: rgba(255, 170, 170, 0.3333333333);
  bottom: 100px;
  left: 10vw;
  z-index: 1001;
  border-radius: 20px;
  padding: 5px 30px;
}

.section-center {
  max-width: calc(1.5 * 600px);
  margin: auto;
}/*# sourceMappingURL=index.css.map */