$card-height: 100px;
$card-padding: 10px;
$column-gap: 10px;

@mixin card-base {
    cursor: pointer;
    user-select: none;

    // Card widths 1-4 columns
    @media screen and (max-width: $mobile-breakpoint) {
        width: calc(100%);
    }

    @media screen and (min-width: $mobile-breakpoint) and (max-width: calc(2 * #{$mobile-breakpoint})) {
        width: calc(50% - #{$column-gap} / 2);
    }

    @media screen and (min-width: calc(2 * #{$mobile-breakpoint})) and (max-width: calc(3 * #{$mobile-breakpoint})) {
        width: calc(33.333333% - 2 * #{$column-gap} / 3);
    }

    @media screen and (min-width: calc(3 * #{$mobile-breakpoint})) {
        width: calc(25% - 3* #{$column-gap} / 4);
    }

    border-radius: 10px;
    box-sizing: border-box;

    background-color: white;
    border: 1px solid #eee;
    @include shadow;

}

.card-container {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $column-gap;

    align-items: flex-start;

    .card {
        @include card-base;
        height: $card-height;
        //padding: $card-padding;
    }

    .card-boat {
        @include card-base;
        border-radius: 0;

        .boat-image {
            @include item-image-large;
        }

        .boat-info {
            padding: 10px;
            // display: block;
            min-height: 100px;

            .boat-name {
                font-size: 1.2rem;
            }

            .boat-type {
                margin-top: 4px;
                font-size: 1rem;
            }

            .boat-description {
                display: block;
                margin-top: 4px;
                font-size: 0.8rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                //height: calc(0.9rem * 3);

                /* Needed to make it work */
                overflow: hidden;
            }
        }
    }

    .card-center {
        margin: auto;
    }

    .add-card {
        display: flex;
    }

    .data-card {

        display: grid;
        grid-template-columns: calc(#{$card-height} - #{$card-padding}) auto;
        padding: 10px;

        .card-info {
            display: inline-block;
            width: 100%;
            min-width: 0;
        }


        .card-image {
            width: calc(#{$card-height} - 2*#{$card-padding});
            aspect-ratio: 1;
            background-color: $blue05;
            border-radius: $card-padding;
        }

        .title {
            font-size: 1.2rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
        }

        .card-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
        }

    }
}

.plus-container {
    flex: 1;
    width: 50px;
    aspect-ratio: 1;
    margin: auto;

    background-color: $blue05;
    color: $blue30;
    border-radius: 50%;

    display: flex;
    flex-direction: column;

    .icon {
        margin-top: 7px;
        display: block;
        flex: 1;
    }
}