table {
    border-collapse: collapse;

    &.tbl {
        width: 100%;
        border-top: 1px solid #ccc;
        font-size: 16px;

        th {
            white-space: nowrap;
            text-align: left;
            text-transform: uppercase;
        }

        tr {
            border-bottom: 1px solid #ccc;

            &:nth-child(odd) {
                background-color: #fff;
            }

            td {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 0;
            }

            &.large {
                font-size: 18px;
            }

            &.clickable {
                cursor: pointer;

                &:hover {
                    background-color: $blue05;
                }
            }
        }

    }

    &.tbl-small {
        font-size: 0.8rem;
    }
}