.frm-section {
    // max-width: $mobile-breakpoint;
    background-color: white;
    padding: 10px;
    padding-bottom: 20px;
    border-radius: 10px;
}

.frm-editable {
    display: flex;
    flex-direction: row;

    .content {
        flex: 1;
        padding-right: 20px;
    }

    .edit-ico {
        cursor: pointer;
        flex: 0;
        flex-basis: 50px;
        display: flex;
        align-items: center;
        text-align: center;
    }
}

.frm {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 1px solid $blue10;

    label {
        display: block;
        font-weight: 500;
        user-select: none;
    }

    input,
    textarea,
    select {
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: calc(100% - 20px);
        font-size: 16px;
        font-family: 'Roboto', sans-serif;

        &::placeholder {
            font-style: italic;
        }
    }

    select {
        width: 100%;
    }

    textarea {
        min-height: 100px;
    }

    .data {
        font-size: 1.2rem;
        min-height: 1.3rem;
        white-space: pre-wrap;
    }
}

.frm-image {
    margin: -10px -10px 0px -10px;

    .image {
        @include item-image-large;
    }
}

.btn-container {
    padding: 0px 10px;
}

@mixin btns {
    display: block;
    padding: 5px 10px;
    border-radius: 20px;
    height: 40px;
    width: 100%;
    padding: 5px 10px;
    white-space: nowrap;
    padding: 5px 15px;
    max-width: calc(0.8 * #{$mobile-breakpoint});
    font-size: 16px;
    margin: auto;

    &:disabled {
        opacity: 0.5;
    }
}


.btn-primary {
    @include btns;
    border: 1px solid $blue20;
    background-color: $blue20;
    color: white;

}

.btn-secondary {
    @include btns;
    border: 1px solid $gray20;
    background-color: $gray80;
    color: $gray10;
}