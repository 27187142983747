.settings-container {
    background-color: white;
    @include shadow;
    padding: 10px 0px;
    border-radius: 20px;
    margin-bottom: 20px;

    .header {
        padding: 0px 10px;
        font-size: 1.2rem;
        color: $blue40;
        border-bottom: 1px solid $blue10;
    }

    .settings-item {
        display: grid;
        grid-template-columns: auto 40px;
        cursor: pointer;

        padding: 8px;
        padding-left: 16px;

        .label {}

        .description {
            font-size: 0.8rem;
            font-style: italic;
        }

        .ico {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
}

.settings-item:not(:last-child) {
    border-bottom: 1px solid $blue05;
}