html,
body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  // Roboto
  // @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

  //@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');

  // @import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&family=Roboto+Condensed&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&family=Roboto&family=Roboto+Condensed&display=swap');


  font-family: 'Roboto', sans-serif;
  // font-family: 'Open Sans', sans-serif;
  // font-family: 'Montserrat', sans-serif;

  //font-family: 'Roboto Condensed', sans-serif;

  margin: 0;
  //font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@mixin shadow {
  box-shadow: 5px 4px 7px -4px rgba(0, 0, 0, 0.42);
}

@mixin item-image-large {
  width: 100%;
  aspect-ratio: 1.7;
  object-fit: cover;
}

@mixin item-image-icon {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

@import "./styles/dimensions";
@import "./styles/colors";
@import "./styles/main-ui";
@import "./styles/tbl";
@import "./styles/overlay";
@import "./styles/forms";
@import "./styles/leftmenu";
@import "./styles/fonts";
@import "./styles/account-dialog";
@import "./styles/dialog";
@import "./styles/toolbar";
@import "./styles/card";
@import "./styles/settings";
@import "./styles/events";
@import "./styles/file-upload";


@media screen and (max-width: $mobile-breakpoint) {
  .hide-on-mobile {
    display: none;
  }
}

.limit-width-to-mobile {
  max-width: $mobile-breakpoint;
}

.wide-container {
  padding-left: 10px;
  padding-right: 10px;
}

.flex-container-with-header {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - #{$ui-top-height} - #{$ui-bottom-menu-height});
}

#bottomdialog {
  position: absolute;
  background-color: #faa5;

  bottom: 100px;
  left: 10vw;
  // width: 80vw;
  z-index: 1001;
  border-radius: 20px;
  padding: 5px 30px;
}

.section-center {
  max-width: calc(1.5 * #{$mobile-breakpoint});
  margin: auto;
}