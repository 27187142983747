#ui-login {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: $topmenu-background;
    height: 100dvh;
    width: 100vw;
    padding-top: 100px;

    .login-box {
        background-color: $white;
        height: 140px;
        width: 500px;
        max-width: 80vw;
        margin: auto;
        border-radius: 20px;
        padding: 20px;
        padding-bottom: 50px;

        &.disabled {
            filter: grayscale(1);
            pointer-events: none;
            opacity: 0.7;
        }

        .login-failed {
            color: red;
            text-transform: uppercase;
            text-align: center;
        }
    }

}


#ui-main {
    display: flex;
    flex-direction: column;
    height: 100dvh;

    #ui-main-left {
        flex: 0;
        flex-direction: column;
        flex-basis: $ui-top-height;
        background-color: $topmenu-background;
    }

    #ui-main-top {
        flex: 0;
        flex-basis: $ui-top-height;
        background-color: $topmenu-background;
        color: $white;


        .ui-main-top-upper {
            flex: 1;
            display: flex;
            flex-direction: row;

            button {
                height: 100%;
            }
        }

        .ui-main-top-links {
            flex: 0;
            flex-basis: 0px;
            display: flex;
            flex-direction: row;

            .link {
                min-width: 200px;
                background-color: $blue40;
                color: $white;
                height: 100%;
                cursor: pointer;

                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 15px;

                &:hover {
                    // text-decoration: underline;
                    background-color: $blue50;
                }
            }

        }

        display: flex;
        flex-direction: column;

    }

    #ui-main-content {
        flex: 1;
        background-color: $body-background;
        // padding: 10px;
        height: calc(100dvh - #{$ui-top-height});
        overflow-y: scroll;

    }

    // Mobile
    @media screen and (max-width: $mobile-breakpoint) {
        #ui-bottom-menu {
            flex: 0;
            flex-basis: $ui-bottom-menu-height;
            background-color: transparent;

            #ui-bottom-menu-ui {
                margin: auto;
                max-width: $mobile-breakpoint;
                height: $ui-bottom-menu-height;
                background-color: $topmenu-background;
            }
        }
    }

    // Desktop
    @media screen and (min-width: $mobile-breakpoint) {
        #ui-bottom-menu {
            position: absolute;
            width: $mobile-breakpoint;
            bottom: 0px;
            left: calc(50dvw - #{$mobile-breakpoint} / 2);
            background-color: transparent;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            overflow: hidden;

            #ui-bottom-menu-ui {
                margin: auto;
                height: $ui-bottom-menu-height;
                background-color: $topmenu-background;

            }
        }
    }




    @mixin menuitem {
        user-select: none;
        cursor: pointer;
        gap: 10px;
        //background-color: white;
        color: white;
        text-align: center;

        .icon {
            margin-top: 8px;
            text-align: center;
        }

        .label {
            margin-top: 5px;
            font-size: 0.8rem;
        }
    }

    #ui-bottom-menu-ui {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

        .menuitem {
            user-select: none;
            cursor: pointer;
            gap: 10px;
            //background-color: white;
            color: white;
            text-align: center;
            margin-bottom: env(safe-area-inset-bottom, 0px);

            .icon {
                margin-top: 8px;
                text-align: center;
            }

            .label {
                margin-top: 5px;
                font-size: 0.8rem;
            }

            &.active {
                background-color: lighten($topmenu-background, 10%);

            }
        }
    }



    #ui-main-bottom {
        display: flex;
        flex-direction: row;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 1;
    }
}

// Flex classes

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.flex0 {
    flex: 0;
}

.flex1 {
    flex: 1;
}

.flexb100 {
    flex: 0;
    flex-basis: 100px;
}

.flexb10 {
    flex: 0;
    flex-basis: 10px;
}

.flexb20 {
    flex: 0;
    flex-basis: 20px;
}

.flexb40 {
    flex: 0;
    flex-basis: 40px;
}

.w100 {
    width: 100%;
}

.w90 {
    width: 90%;
}

.w50 {
    width: 50%;
}

.p10 {
    padding: 10px;
}

.ph10 {
    padding: 0px 10px;
}

.m10 {
    margin: 10px;
}

.pb50 {
    padding-bottom: 50px;
}

.page-header {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 10px;
    color: $blue40;
    user-select: none;

    .header {
        flex: 1;
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .menu {
        flex: 0;
        flex-basis: 40px;
        padding-top: 5px;
        padding-right: 10px;
        text-align: right;
        cursor: pointer;
    }
}