#overlay,
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100dvh;
    animation: fadein 0.3s;

    &.dark {
        background-color: $overlay-background;
    }

    &.darker {
        background-color: $overlay-background-dark;
    }


    &.hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.2s, opacity 0.2s linear;
    }

    .spinner {
        color: #fffc;
        height: 100dvh;
        width: 100dvw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .progress-bar {
            margin-top: 20px;
            width: 50%;
            margin: auto;
            border: 1px solid white;

            .progress {
                width: 50%;
                height: 20px;
                background-color: #fff7;
            }
        }
    }
}