// Colors
$white: #fff;
$black: #000;
$blue: rgb(137, 165, 207);

$gray80 : darken($white, 80%);
$gray50 : darken($white, 50%);
$gray20 : darken($white, 20%);
$gray10 : darken($white, 10%);

$blue80 : darken($blue, 80%);
$blue50 : darken($blue, 50%);
$blue40 : darken($blue, 40%);
$blue30 : darken($blue, 30%);
$blue20 : darken($blue, 20%);
$blue10 : darken($blue, 10%);
$blue05 :#cbdcf6;
$blue01 :#e4efff;



$gray80 : darken($white, 80%);
$gray50 : darken($white, 50%);
$gray20 : darken($white, 20%);
$gray10 : darken($white, 10%);


// Specific
$overlay-background: #0006;
$overlay-background-dark: #0009;
$body-background :#eee;
$topmenu-background : $blue40; //#666;