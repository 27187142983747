.file-upload,
.image-upload {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;


    .container {
        background-color: #ffffff;
        width: 90%;
        max-width: 34.37em;
        position: relative;
        margin: 3.12em auto;
        padding: 3.12em 1.25em;
        border-radius: 0.43em;
        //box-shadow: 0 1.25em 2.18em rgb(1, 28, 71, 0.3);
        @include shadow;
    }

    input[type="file"] {
        display: none;
    }

    label {
        display: block;
        position: relative;
        background-color: $blue30;
        color: #ffffff;
        font-size: 1.12em;
        font-weight: 500;
        text-align: center;
        width: 18.75em;
        padding: 10px 0;
        margin: auto;
        border-radius: 0.31em;
        cursor: pointer;
    }

    .camera-input {
        max-width: 120px;
        aspect-ratio: 1.6;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .num-of-files {
        font-weight: 400;
        text-align: center;
        margin: 1.25em 0 1.87em 0;
    }

    .card-container {
        margin-top: 10px;
    }

    .file-card {
        font-weight: 500;
        // background-color: $blue01;
        color: $blue30;
        // margin-bottom: 1em;
        padding: 1.1em 1em;
        // border-radius: 0.3em;
        display: flex;
        justify-content: space-between;

    }

    .image-card {
        position: relative;
        font-weight: 500;
        // background-color: $blue01;
        color: $blue30;
        // margin-bottom: 1em;
        margin: 1.1em 1em;
        // border-radius: 0.3em;

        text-align: center;

        .image {
            width: 100%;
            border-radius: 10px;
            //aspect-ratio: 1;
            object-fit: cover;
        }


        .image-preview {
            height: 100dvh;
            width: 100dvw;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .image-preview-img {
                width: auto;
                max-height: 100dvh;
                max-width: 100dvw;
                border-radius: 0;
                touch-action: pan-right pinch-zoom;
                object-fit: contain;
            }
        }

        .remove {
            position: absolute;
            width: 40px;
            aspect-ratio: 1;
            right: 0px;
            background-color: #fff9;
            border-radius: 10px;
            color: black;

            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        // .image {
        //     position: relative;
        // }

        .filesize {
            display: none;
        }

    }

}


.image-upload-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}