.toolbar-container {
    display: flex;
    flex-direction: row;
    margin: 4px 0px;
    user-select: none;

    .toolbar-item {

        flex: 0;

        cursor: pointer;
        user-select: none;
        border: 1px solid $blue40;
        margin-right: 10px;
        white-space: nowrap;
        padding: 5px 15px;
        padding-right: 5px;
        border-radius: 8px;
        background-color: $blue20;
        color: white;

        .icon {
            padding-right: 10px;
        }

        .text {
            padding-right: 10px;
        }

        &.disabled {
            background-color: $blue10;
            opacity: 0.8;
            cursor: default;
        }

    }
}