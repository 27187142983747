.overflow-scroll {
    overflow-y: scroll;
}



.events {

    .event {
        @include shadow;
        background-color: white;
        min-height: 80px;

        display: grid;
        grid-template-columns: 60px auto;

        border-radius: 10px;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;

        .ico {
            background-color: $blue05;
            aspect-ratio: 1;
            border-radius: 50%;

            img {
                border-radius: 50%;
                @include item-image-icon;
            }
        }

        .texts {
            padding-left: 10px;

            .title {
                font-size: 1.2rem;
            }

            .timestamp {
                min-width: 0;
                font-size: 0.8rem;
                white-space: nowrap;
                overflow: hidden;
            }

            .description {
                min-width: 0;
                font-size: 0.8rem;
                overflow: hidden;

                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                //height: calc(0.9rem * 2);
            }
        }


    }
}